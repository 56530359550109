<template>
    <div>
        <form id="contato">
            <div>
                <input v-model="name" type="text" placeholder="Nome" />
            </div>
            <div>
                <input v-model="mail" type="text" placeholder="E-mail" />
            </div>
            <div>
                <input
                    v-model="phone"
                    type="text"
                    placeholder="Telefone para contato"
                    v-maska="['(##) #####-####', '(##) ####-####']"
                />
            </div>
            <div>
                <textarea
                    v-model="message"
                    placeholder="Comentários"
                ></textarea>
            </div>
            <div>
                <button type="button" v-on:click="sendEmail()">Enviar</button>
            </div>
            <div class="validation" v-bind:class="[success ? 'success' : '']">
                {{ validation }}
            </div>
        </form>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Contato",
    data() {
        return {
            name: "",
            mail: "",
            phone: "",
            message: "",
            validation: "",
            success: false,
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, //eslint-disable-line
        };
    },
    methods: {
        sendEmail() {
            if (
                this.name.length == 0 ||
                this.mail.length == 0 ||
                this.phone.length == 0 ||
                this.message.length == 0
            ) {
                this.validation = "Por favor, preencha todos os campos!";
                return;
            }

            if (this.reg.test(this.mail)) {
                this.validation = "";
            } else {
                this.validation = "Por favor, insira um e-mail válido";
                return;
            }

            this.validation = "";
            this.success = false;
            axios
                .post(
                    "https://us-central1-msnunesfaggion.cloudfunctions.net/emailMessage",
                    {
                        name: this.name,
                        email: this.mail,
                        phone: this.phone,
                        message: this.message,
                    }
                )
                .then(() => {
                    this.name = "";
                    this.mail = "";
                    this.phone = "";
                    this.message = "";
                    this.success = true;
                    this.validation = "Mensagem enviada com sucesso!";
                })
                .catch((e) => {
                    console.log(e);
                    this.validation =
                        "Falha ao enviar mensagem. Por favor, tente novamente mais tarde!";
                });
        },
    },
};
</script>
<style scoped>
input[type="text"] {
    width: 520px;
    padding: 10px;
    height: 48px;
    margin: 5px;
    border: none;
    border: 0.5px solid #a7a7a7;
    border-radius: 2px;
}
textarea {
    width: 520px;
    padding: 10px;
    height: 100px;
    margin: 5px;
    border: none;
    border: 0.5px solid #a7a7a7;
    border-radius: 2px;
}
button {
    width: 520px;
    margin: 5px;
    border: none;
    padding: 0px 40px;
    height: 42px;
    background-color: #56276c;
    text-align: center;
    font-size: 16px;
    font-family: Montserrat;
    letter-spacing: 0.7px;
    line-height: 0;
    color: #ffffff;
    min-width: 230px;
}
input[type="text"]::placeholder {
    text-align: left;
    font: normal normal bold 12px/18px Montserrat;
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;
}
textarea::placeholder {
    text-align: left;
    font: normal normal bold 12px/18px Montserrat;
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;
}
.validation {
    color: rgb(219, 73, 73);
    text-align: center;
    font: normal normal bold 14px/20px Montserrat;
}
.validation.success {
    color: rgb(255, 255, 255);
}
@media only screen and (max-width: 600px) {
    input[type="text"] {
        width: 350px;
    }
    textarea {
        width: 350px;
    }
    button {
        width: 350px;
    }
}
</style>