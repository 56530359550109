<template>
    <div id="nav" class="container-fluid">
        <div class="header" v-bind:class="[isSticky ? stickyClass : '']">
            <div class="container hd">
                <div class="logo">
                    <a href="/"
                        ><img
                            class="d-block d-md-none"
                            src="./assets/Logo_home_menu.svg"
                        />
                        <img
                            class="d-none d-md-block"
                            v-if="!isSticky"
                            src="./assets/Logo_home.svg"
                        />
                        <img
                            class="d-none d-md-block"
                            v-if="isSticky"
                            src="./assets/Logo_home_menu.svg"
                        />
                    </a>
                </div>
                <div class="controls d-flex d-md-none">
                    <div class="redes">
                        <a href="https://wa.me/+5511988073158" target="_blank">
                            <img src="./assets/ic_whats.svg" alt=""
                        /></a>
                        <a
                            href="https://facebook.com/msnunesfaggion"
                            target="_blank"
                            ><img src="./assets/ic_facebook.svg" alt=""
                        /></a>
                        <a
                            href="https://www.instagram.com/msnunesfaggion/"
                            target="_blank"
                            ><img src="./assets/ic_insta.svg" alt=""
                        /></a>
                    </div>
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <img
                            class="navbar-toggler-icon"
                            :src="mobileButton"
                            @click="toggleButton"
                            alt=""
                        />
                    </button>
                </div>
                <nav class="navbar navbar-expand-lg menu">
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <a href="/">Home</a>
                            <a href="/escritorio">O Escritório</a>
                            <a href="/atuacao">Áreas de Atuação</a>
                            <a href="/contato">Contato</a>
                        </ul>
                    </div>
                </nav>
                <div class="redes-web">
                    <a href="https://wa.me/+5511988073158" target="_blank">
                        <img src="./assets/ic_whats.svg" alt=""
                    /></a>
                    <a
                        href="https://facebook.com/msnunesfaggion"
                        target="_blank"
                        ><img src="./assets/ic_facebook.svg" alt=""
                    /></a>
                    <a
                        href="https://www.instagram.com/msnunesfaggion/"
                        target="_blank"
                        ><img src="./assets/ic_insta.svg" alt=""
                    /></a>
                </div>
            </div>
        </div>
        <div class="container hd-content">
            <HeaderContent :Title="this.$route.name"></HeaderContent>
        </div>
    </div>
    <router-view />
    <footer :class="this.$route.name == 'Home' ? '' : 'bg'">
        <p v-if="this.$route.name == 'Home'">
            MS Nunes Faggion, 2021. Todos os direitos reservados.
            <a href="#" target="_blank" rel="Política de Privacidade"
                >Política de Privacidade</a
            >
        </p>
        <div class="container" v-if="this.$route.name !== 'Home'">
            <Footer></Footer>
        </div>
    </footer>
</template>

<script>
import HeaderContent from "./components/HeaderContent.vue";
import Footer from "./components/Footer.vue";

export default {
    name: "App",
    components: {
        HeaderContent,
        Footer,
    },
    data() {
        return {
            isSticky: false,
            stickyClass: "is_sticky",
            scrollPosition: 0,
            mobileButton: require("./assets/ic_hamburger_ppurple.svg"),
            close: false,
        };
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    mounted() {
        var Tawk_API = Tawk_API || {},
            // eslint-disable-next-line no-unused-vars
            Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"),
                s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = "https://embed.tawk.to/61e89ee8f7cf527e84d30c65/1fpqapfpq";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
        })();
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.scrollPosition = window.scrollY;
            if (this.scrollPosition >= 100) {
                this.isSticky = true;
            } else {
                this.isSticky = false;
            }
        },
        toggleButton() {
            if (!this.close) {
                this.mobileButton = require("./assets/ic_hamburger_close.svg");
                this.close = true;
            } else {
                this.mobileButton = require("./assets/ic_hamburger_ppurple.svg");
                this.close = false;
            }
        },
    },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#app {
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    background-image: url("./assets/bg_header.high_blur.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

#nav .logo img {
    height: 120px;
    margin: 10px;
}

#nav .menu {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 27px;
}
#nav .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
}

#nav .header,
.hd {
    display: flex;
}
@media (min-width: 768px) {
    #nav .is_sticky .menu {
        margin-top: 0px;
    }
    .is_sticky .hd {
        display: flex;
        align-items: center;
    }
    #nav .header.is_sticky {
        position: fixed;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        left: 0px;
        right: 0px;
        z-index: 9999;
    }
    #nav .header.is_sticky a {
        color: #56276c;
        opacity: 0.67 !important;
        font: normal normal 600 13px/16px Montserrat !important;
    }
    #nav .header.is_sticky img {
        height: 80px;
        margin: 10px 0px;
    }
    #nav .is_sticky a.router-link-exact-active {
        font: normal normal 600 13px/16px Montserrat !important;
        letter-spacing: 0.47px;
        color: #56276c;
        opacity: 1;
    }
    #nav .is_sticky .redes-web {
        display: flex;
        width: 110px;
        height: 80px;
        justify-content: space-between;
        align-items: center;
        margin-left: 30px;
    }
    #nav .is_sticky .redes-web img {
        height: 25px;
    }
}

#nav .menu a {
    text-decoration: none;
    font: normal normal 400 13px/16px Montserrat !important;
    letter-spacing: 0.47px;
    color: #ffffff;
    opacity: 0.67 !important;
    text-transform: uppercase;
    padding: 0px 30px;
}

#nav a:hover {
    opacity: 100%;
}

#nav a.router-link-exact-active {
    font: normal normal 500 13px/16px Montserrat !important;
    opacity: 100%;
}
footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
footer.bg {
    background: #f7f7f7 0% 0% no-repeat padding-box;
    height: auto;
}
footer p {
    margin: 0px;
    text-align: center;
    font: normal normal normal 13px/16px Montserrat;
    letter-spacing: 0px;
    color: #56276c;
}
footer a {
    text-align: center;
    font: normal normal normal 13px/16px Montserrat;
    letter-spacing: 0px;
    color: #56276c;
    opacity: 1;
}

#nav .redes-web {
    display: flex;
    width: 110px;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
}

@media only screen and (max-width: 600px) {
    #nav {
        background-image: url("./assets/bg_header_mobile.png");
    }
    #nav {
        padding: 0px;
    }
    #nav .header {
        background: white;
        position: fixed;
        width: 100%;
        z-index: 9999;
    }
    #nav .header img {
        height: 80px;
    }
    #nav .navbar {
        position: absolute;
        width: 100%;
        top: 73px;
        left: 0px;
        background: white;
        text-align: center;
    }
    #nav .navbar a {
        padding: 20px 0px;
        font: normal normal 600 13px/16px Montserrat;
        letter-spacing: 0.47px;
        color: #000000;
        opacity: 0.67;
    }
    #nav .hd {
        justify-content: space-between;
    }
    #nav .hd .controls {
        display: flex;
    }
    #nav .hd .controls img {
        width: 16px;
        height: 16px;
    }
    #nav .hd .controls .redes {
        width: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 25px;
    }

    .navbar-toggler-icon {
        width: 20px;
        height: 20px !important;
    }
    .navbar-toggler:focus {
        box-shadow: none !important;
    }
    #nav .hd-content {
        padding-top: 80px;
    }

    #nav .redes-web {
        display: none;
    }
}
</style>
