<template>
    <div class="container atuacao">
        <div class="row">
            <p>
                A MS Nunes Faggion Sociedade de Advogados oferece aos nossos
                clientes, soluções ágeis e inovadoras através de serviços nas
                áreas do contencioso judicial e consultivo jurídico, abrangendo
                o Direito Civil, Consumidor, Trabalho e Digital, contando, para
                tanto, com o auxílio de profissionais especializados e
                capacitados com dedicação exclusiva para cada cliente, nos
                seguintes seguimentos:
            </p>
        </div>
        <div class="row">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link active"
                        id="pills-trabalhista-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-trabalhista"
                        type="button"
                        role="tab"
                        aria-controls="pills-trabalhista"
                        aria-selected="true"
                    >
                        TRABALHISTA
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="pills-civil-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-civil"
                        type="button"
                        role="tab"
                        aria-controls="pills-civil"
                        aria-selected="false"
                    >
                        DIREITO CIVIL
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="pills-familia-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-familia"
                        type="button"
                        role="tab"
                        aria-controls="pills-familia"
                        aria-selected="false"
                    >
                        DIREITO DE FAMÍLIA
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="pills-consumidor-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-consumidor"
                        type="button"
                        role="tab"
                        aria-controls="pills-consumidor"
                        aria-selected="false"
                    >
                        CONSUMIDOR
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="pills-previdenciario-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-previdenciario"
                        type="button"
                        role="tab"
                        aria-controls="pills-previdenciario"
                        aria-selected="false"
                    >
                        DIREITO PREVIDENCIÁRIO
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div
                    class="tab-pane fade show active"
                    id="pills-trabalhista"
                    role="tabpanel"
                    aria-labelledby="pills-trabalhista-tab"
                >
                    <p>
                        A área trabalhista é considerada uma área vasta do
                        direito que rege as relações entre empregado e
                        empregador, visando proteger as relações trabalhistas
                        contra ato/omissões ocasionadas tanto pelo empregador
                        como pelo empregado.
                    </p>

                    <p></p>
                    <p>
                        As ações trabalhistas, também denominadas, reclamações
                        trabalhistas são ações propostas pelos empregados em
                        face da empresa, em busca de que os direitos previstos
                        em legislação própria sejam garantidos, contudo, o
                        empregador também poderá se valer desta medida judicial
                        em caso de danos causados por funcionários à empresa.
                        Para isto, o empregado ou o empregador, deve procurar a
                        assessoria de um advogado ou de um escritório de
                        advocacia para propor a ação ou apresentar defesa
                        trabalhista.
                    </p>

                    <p></p>
                    <p>
                        O nosso Escritório é especializado na área trabalhista,
                        com profissionais qualificados para auxiliar nossos
                        clientes, pessoas físicas ou jurídicas, na parte
                        consultiva e judicial, prevenindo futuros litígios
                        através da assessoria e atuando na via judicial quando
                        necessário.
                    </p>
                    <p>Dentre as principais atividades, podemos destacar:</p>
                    <div class="list">
                        <p>Reclamação trabalhista</p>
                        <p>Defesas trabalhistas</p>
                        <p>Equiparação salarial</p>
                        <p>Readequação de função</p>
                        <p>Jornada extra</p>
                        <p>Indenizações</p>
                        <p>Danos morais</p>
                        <p>Reversão de dispensa por justa causa</p>
                        <p>e Assessoria jurídica.</p>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="pills-civil"
                    role="tabpanel"
                    aria-labelledby="pills-civil-tab"
                >
                    <p>
                        Os conflitos são um fato da vida, sejam eles resultado
                        de má-fé, erros ou descuidos, mas, para todas estas
                        situações, existem remédios jurídicos. Se o seu caso diz
                        respeito a um conflito com outra pessoa ou com uma
                        empresa privada, envolvendo bens ou interesses
                        econômicos, provavelmente, você necessitará de um
                        especialista na área do Direito Cível.
                    </p>

                    <p />
                    <p>
                        O nosso Escritório tem relevante atuação no patrocínio
                        de ações judiciais focadas na esfera Cível, além da
                        vasta competência para a elaboração de pareceres,
                        consultorias e assessorias nesta área.
                    </p>
                    <p>
                        Apenas para demonstrar o quão vasta é a área Cível,
                        abaixo segue uma lista meramente exemplificativa dos
                        procedimentos em que nossos profissionais estão
                        preparados para atuar e lhe auxiliar, vejamos:
                    </p>
                    <div class="list">
                        <p>Ações de cobrança</p>
                        <p>Ações de execução judicial</p>
                        <p>Bens móveis e imóveis</p>
                        <p>Direito Condominial</p>
                        <p>Direito Empresarial</p>
                        <p>Direitos de Vizinhança</p>
                        <p>Elaboração e discussão de Contratos</p>
                        <p>Falências e Concordatas</p>
                        <p>Indenizações por Danos Morais e Danos Materiais</p>
                        <p>Locações de Imóveis</p>
                        <p>Mudança de Nome e Retificação de Registro</p>
                        <p>Posse e Propriedade</p>
                        <p>Recuperação de Crédito</p>
                        <p>Registro Civil e Imobiliário</p>
                        <p>Sociedades Comerciais</p>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="pills-familia"
                    role="tabpanel"
                    aria-labelledby="pills-familia-tab"
                >
                    <p>
                        O direito de família está voltado para questões que
                        envolvam, casamento, relações pessoais e econômicas da
                        sociedade conjugal, a dissolução desta, a união estável,
                        as relações entre pais e filhos, vínculos naturais e de
                        afinidades, além dos institutos complementares da tutela
                        e curatela.
                    </p>
                    <p>
                        O Escritório tem o condão de auxiliar nossos clientes
                        nos aspectos envolvendo o direito de família, para isso,
                        contamos com um time qualificado para intervir,
                        resguardando nossos clientes das exposições, sejam elas
                        emocionais, ou financeiras.
                    </p>
                    <p>
                        Abaixo, elencamos algumas matérias da esfera familiar
                        que nossos profissionais atuam:
                    </p>
                    <div class="list">
                        <p>Alienação Parental</p>
                        <p>Alimentos e Guarda</p>
                        <p>Autorizações para viagens de menores</p>
                        <p>Divórcios judiciais ou extrajudiciais</p>
                        <p>Holding Familiar</p>
                        <p>Inventários judiciárias ou extrajudiciais</p>
                        <p>Investigação de Paternidade</p>
                        <p>Pacto Antenupcial</p>
                        <p>Paternidade Socioafetiva</p>
                        <p>Planejamento sucessório e proteção patrimonial</p>
                        <p>Reconhecimento de União Estável</p>
                        <p>Regime de Bens</p>
                        <p>Regulamentação de visitas</p>
                        <p>Testamentos judiciais ou extrajudiciais</p>
                        <p>Tutela e curatela</p>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="pills-consumidor"
                    role="tabpanel"
                    aria-labelledby="pills-consumidor-tab"
                >
                    <p>
                        Participar dos diversos paradigmas consumeristas é algo
                        inerente ao ser humano, já que vivemos em um sistema
                        capitalista.
                    </p>
                    <p>
                        O Direito do Consumidor é o ramo do direito que garante
                        a efetiva proteção do consumidor no mercado de consumo,
                        trazendo equilíbrio nos vínculos consumeristas.
                    </p>
                    <p>
                        O MS Nunes Faggion construiu sólida experiência com
                        atuação na área do direito do consumidor. Nossa equipe
                        de excelência, presta atendimento aos nossos clientes de
                        forma preventiva e na solução de litígios, adequando o
                        conhecimento técnico e prático aos casos. Atuamos com
                        ênfase nas seguintes matérias:
                    </p>
                    <div class="list">
                        <p>Indenizações por danos morais e materiais</p>
                        <p>
                            Juros abusivos em contratos bancários e financeiros
                        </p>
                        <p>
                            Acompanhamento em órgãos de proteção ao consumidor
                            (Procon)
                        </p>
                        <p>Problemas em compras feitas pela internet</p>
                        <p>Atraso de voos, extravio de bagagem e overbooking</p>
                        <p>Cobranças indevidas e ligações abusivas</p>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="pills-previdenciario"
                    role="tabpanel"
                    aria-labelledby="pills-previdenciario-tab"
                >
                    <p>
                        O Direito Previdenciário é uma área do direito público e
                        tem como objetivo o estudo e regulamentação da
                        seguridade social. Previsto no artigo 6º, da
                        Constituição Federal de 1988, o Direito Previdenciário
                        visa resguardar os beneficiários, sejam eles segurados
                        ou dependentes, quando estes, se encontrarem em situação
                        de necessidade social.
                    </p>
                    <p>
                        Com o advento da Reforma da Previdência, muitas dúvidas
                        surgiram quanto as novas regras para a concessão dos
                        benefícios previdenciários, em razão disso, nossos
                        profissionais se capacitaram para atuarem tanto na
                        esfera administrativa, quanto judicial, promovendo todas
                        as demandas para concessão dos benefícios, como:
                    </p>
                    <div class="list">
                        <p>
                            aposentadorias, por tempo, idade, invalidez
                            (incapacidade),
                        </p>
                        <p>auxílio-doença e acidente,</p>
                        <p>Benefícios para deficientes e idosos (LOAS-BPC),</p>
                        <p>pensão por morte, revisão, entre outras.</p>
                    </div>
                    <p>
                        Nossos advogados podem te ajudar a entender como está
                        sua situação perante a Previdência Social e te oferecer
                        a assistência necessária do início ao fim para obtenção
                        de benefício junto à Previdência Social, inclusive, se
                        houver a necessidade de um processo judicial.
                    </p>
                    <p>
                        Quer saber mais sobre este assunto? Agende uma consulto
                        com nossos profissionais e tire suas dúvidas.
                    </p>
                </div>
            </div>
        </div>
        <div class="row agende">
            <p>
                Agende uma consulta com nossos profissionais para tirar suas
                dúvidas.
            </p>
            <router-link to="/contato#"
                ><button>AGENDE SUA CONSULTA</button></router-link
            >
        </div>
    </div>
</template>
<script>
export default {
    name: "Áreas de Atuação",
    components: {},
};
</script>
<style scoped>
.atuacao {
    margin-top: 30px;
}
.atuacao p:first-child {
    margin-bottom: 0px;
}
#pills-tab {
    margin-top: 16px;
}
#pills-tabContent p:first-child {
    margin-bottom: 16px;
}
.atuacao p {
    text-align: left;
    font: normal normal normal 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;
}
.atuacao .nav-pills .nav-link {
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0.58px;
    color: #56276c;
    opacity: 0.5;
}
.atuacao .nav-pills .nav-link.active {
    background-color: initial;
    text-align: left;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0.58px;
    color: #56276c;
    opacity: 1;
    border-bottom: 3px solid #555555;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.atuacao .list {
    margin-top: 35px;
}
.atuacao .list p {
    text-align: center;
    font: normal normal normal 16px/24px Montserrat;
    font-weight: 500;
    letter-spacing: 0.58px;
    color: #56276c;
    opacity: 1;
    margin: 3px 0px 0px 0px !important;
}
.atuacao .agende {
    margin-top: 35px;
    margin-bottom: 60px;
}
.atuacao .agende a {
    width: auto;
    margin-top: 18px;
}
.atuacao .agende button {
    width: 270px;
    border: none;
    padding: 0px 40px;
    height: 42px;
    background-color: #56276c;
    text-align: center;
    font: normal normal normal 13px/16px Montserrat;
    letter-spacing: 0.7px;
    color: #ffffff;
    opacity: 1;
}
.atuacao .nav {
    justify-content: space-between;
    width: 980px;
}
@media only screen and (max-width: 600px) {
    .atuacao {
        padding: 0px 24px;
    }
    .atuacao .agende {
        text-align: center;
    }
    .atuacao #pills-tab {
        display: flex;
        flex-direction: row;
        align-content: space-around;
        justify-content: space-evenly;
    }
    .atuacao p {
        text-align: justify;
    }
    #pills-tab {
        padding-right: 0px;
    }
    .atuacao .agende a {
        margin-top: 20px !important;
        margin: 0 auto;
    }
}
</style>