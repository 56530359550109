import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Escritorio from '../views/Escritorio.vue'
import Contato from '../views/Contato.vue'
import Atuacao from '../views/Atuacao.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/escritorio',
    name: 'O Escritório',
    component: Escritorio
  },
  {
    path: '/contato',
    name: 'Contato',
    component: Contato
  },
  {
    path: '/atuacao',
    name: 'Áreas de Atuação',
    component: Atuacao
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
})

export default router
