<template>
    <div class="conteudo">
        <div v-if="Title !== 'Home'">
            <h1>{{ Title }}</h1>
        </div>
        <div v-else>
            <h1 class="home">Advocacia especializada.</h1>
            <h2>Excelência jurídica para uma nova era da advocacia.</h2>
            <p>Profissionais aptos para solucionar seus problemas.</p>
            <router-link to="/contato"
                ><button>Agende sua consulta</button></router-link
            >
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderContent",
    props: {
        Title: String,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
h1 {
    text-align: left;
    font: normal normal normal 42px/59px Vollkorn;
    letter-spacing: 1.3px;
    color: #ffffff;
    opacity: 1;
}
.conteudo h2 {
    font: italic normal normal 38px Vollkorn !important;
    letter-spacing: 1.51px;
}
.home {
    text-align: left;
    font: normal normal normal 42px/59px Vollkorn;
    letter-spacing: 1.51px;
    color: #ffffff;
    opacity: 1;
}
.conteudo {
    padding: 45px 0px 45px 0px;
    text-align: left;
    font: normal normal normal 42px/59px Vollkorn;
    letter-spacing: 1.51px;
    color: #ffffff;
    opacity: 1;
}
.conteudo p {
    text-align: left;
    font: normal normal normal 16px/19px Montserrat;
    letter-spacing: 0.58px;
    color: #ffffff;
    opacity: 0.6;
    margin-top: 16px;
}
.conteudo button {
    border: none;
    padding: 0px 40px;
    height: 42px;
    background-color: #56276c;
    text-align: center;
    font-size: 16px;
    font-family: Montserrat;
    letter-spacing: 0.7px;
    line-height: 0;
    color: #ffffff;
}

@media only screen and (max-width: 600px) {
    .conteudo h1 {
        font-size: 37px;
        line-height: 46px;
    }
    .conteudo h2 {
        font-size: 32px !important;
    }
    .conteudo {
        padding: 35px 0px 35px 0px;
        margin-top: 36px;
    }
}
</style>
